@import url('https://fonts.googleapis.com/css2?family=Monda&family=Redressed&display=swap');

body{
  margin:0;
  width:100vw;
  font-family: 'Monda', sans-serif;
  overflow-x: hidden;
}

header{
  width: 100%;
  height: 100vh;
  background:rgba(0,0,0,0.6);
  font-family: 'Redressed', cursive;
  padding:5px;
  box-sizing: border-box;
  color:white;
  position: relative;
}


header > .title{
  font-size:60px;
  display:inline-block;
  margin:10px;
  position:absolute;
  top:40%;
  left: 50%;
  transform: translate(-50%,-50%);
}

header .small{
  display:block;
  margin-top:30px;
  font-size:18px !important;
}

header a{
  color:white;
  display:inline-block;
  margin-left:20px;
  text-decoration: underline;
}

.menu{
  padding:75px;
  min-height:100vh;
  box-sizing: border-box;
  background: #fdfdfd;
}

.menu > .menutab{
  font-size: 24px;
  height:10vh;
}

.menuitemwrapper{
  display:flex;
  flex-wrap: wrap;
}
.menuitemwrapper::after {
  content: "";
  flex-grow: 1;
  min-width: 300px;
  margin-right: 40px; 
}
.menuitem{
  border:1px solid black;
  margin:10px;
  padding:10px;
  border-radius: 5px;
  background: white;
  flex:1;
}
@media only screen and (min-width:750px){
  .menuitem{
    min-width: 300px;
  }
}
.menu > h2{
  text-align: center;
}

.background{
  text-align: center;
  height: 100vh;
  background: url(sushirolls.jpg);  
  background-size:cover;
}

.arrowdown {
  display:inline-block;
  width: 0; 
  height: 0; 
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #0c0c0c;
  margin:3px;
}

@media only screen and (max-width:750px){
  header a{
    color:black;
    padding:30px;
    margin-top:30px;
  }
  header{
    position:static;
    background:none;
    color:black;
  }
  header > .title{
    position:static;
    transform: none;
  }
  header > .small{
    position:static;
    top:0;
    display:block;
    margin-top:30px;
  }
  .background{
    background-size: auto 50%;
    background-repeat: no-repeat;
    background-position: bottom;
  }
  .menuitem{
    min-width: 100%;
  }
}

.location{
  background:#171717;
  min-height:100vh;
  box-sizing: border-box;
  padding:40px;
  color:white;
  font-size:24px;
}

.location > .wrapper {
  display: flex;
  flex-wrap: wrap;
}

.location > h2{
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
}
.location > .wrapper > .hour{
  flex:1;
  min-width: 400px;
}
.hour p{
  font-size: 18px;
  font-weight: 100;
}
footer{
  text-align: center;
  color:white;
  padding:100px;
  background:#252934;
}
footer a{
  color:white !important;
}